<template>
  <div class="lnb">
    <div class="lnbLogo">
      <router-link to="/admin/dashboard">
        <img src="@/assets/images/logo.png" alt="logo" />
      </router-link>
    </div>
    <el-row class="tac mainMenu">
      <el-col :span="12">
        <el-menu class="el-menu-vertical-demo" :default-active="navbarState">
          <el-menu-item index="0">
            <router-link to="/admin/dashboard">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_home.svg"
                  alt="icon"
                  class="normal"
                />
                대시보드
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="1">
            <router-link to="/admin/users">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_group.svg"
                  alt="icon"
                  class="normal"
                />
                회원 관리
              </span>
            </router-link>
          </el-menu-item>
          <!-- <el-menu-item index="2">
            <router-link to="/admin/lecture/manage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_place.svg"
                  alt="icon"
                  class="normal"
                />
                강의 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="3">
            <router-link to="/admin/application/manage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_collection.svg"
                  alt="icon"
                  class="normal"
                />
                지원자 관리
              </span>
            </router-link>
          </el-menu-item>-->
          <!-- <el-submenu index="3">
            <template slot="title">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_tour.svg"
                  alt="icon"
                  class="normal"
                />
                교육/지원자 관리
              </span>
            </template>
            <el-menu-item index="3-1">
              <router-link to="/admin/lecture/manage">
                <span>교육 관리</span>
              </router-link>
            </el-menu-item>
            <el-menu-item index="3-2">
              <router-link to="/admin/application/manage">
                <span>지원자 관리</span>
              </router-link>
            </el-menu-item>
          </el-submenu> -->
          <el-menu-item index="3">
            <router-link to="/admin/application/manage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_tour.svg"
                  alt="icon"
                  class="normal"
                />
                지원자 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="4">
            <router-link to="/admin/notice/manage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_magazine.svg"
                  alt="icon"
                  class="normal"
                />
                공지사항 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="6">
            <router-link to="/admin/review/manage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_register.svg"
                  alt="icon"
                  class="normal"
                />
                리뷰 관리
              </span>
            </router-link>
          </el-menu-item>

          <el-menu-item index="7">
            <router-link to="/admin/banner/manage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_banner.svg"
                  alt="icon"
                  class="normal"
                />
                배너 관리
              </span>
            </router-link>
          </el-menu-item>

          <el-menu-item>
            <router-link to @click.native="logout">
              <span>
                <img
                  src="@/assets/images/icon_svg/icn-logout.svg"
                  alt="icon"
                  class="normal"
                />
                로그아웃
              </span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isActive: false,
      isDropped: false,
    };
  },
  computed: {
    ...mapState(["navbarState", "scheduleCount"]),
  },
  beforeMount() {
    this.adminType = localStorage.getItem("adminType");
  },
  methods: {
    logout() {
      this.$confirm("로그아웃 하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        this.$store.dispatch("LOGOUT");
      });
    },
  },
};
</script>
